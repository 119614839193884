// mediaquery
$breakpoints: (
	'tb': 'screen and (max-width: 839px)',
	'sp': 'screen and (max-width: 479px)',
	'hover': 'screen and (min-width: 769px)'
)
!default;
@mixin mq($breakpoint: sp) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

.mq {
	@include mq(tb) {
		width: auto;
	}
	@include mq(sp) {
		width: auto;
	}
	@include mq(hover) {
		width: auto;
	}
}
//newarrived_blog.tpl
.ss-newarrived-blog {
	background: #f3f3f3;
    padding: 60px 0;
    @include mq() {
        padding: 30px 0;
    }
	&__body {
		width: 100%;
		max-width: 930px;
		margin: 0 auto;
		padding: 0 15px;
	}

	&__tab {
		display: flex;
        margin: 0 -5px;
        overflow: hidden;
        @include mq() {
            margin: 0 -2px;
            flex-wrap: wrap;
        }

		&-button {
			flex-grow: 1;
            padding: 7px;
            margin: 0 5px;
            border: solid 1px #e3e3e3;
            border-radius: 4px;
            background-color: #f7f7f7;
            color: #111111;
            font-size: 14px;
            cursor: pointer;
            @include mq() {
                margin: 2px;
                width: calc(50% - 4px);
            }
            &.is-active {
                background-color: #111111;
                color: #ffffff;
            }
            &:hover {
                @include mq(hover) {
                    background-color: #111111;
                    color: #ffffff;
                }
            }
			&-name {
				display: block;
                font-size: 14px;
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
			}
		}
	}

	&__contents {
        margin: 18px 0 0;
    }

	&__panel {
		display: none;

		&.is-active {
			display: block;
		}
        &-list {
            margin: 0;
        }
	}

    &__link {
        display: inline-block;
        @include mq() {
            display: block;
        }
    }

	&__post {
        display: flex;
        padding: 5px 0;
        @include mq() {
            flex-direction: column;
        }
        &-heading {
            display: flex;
            width: 240px;
            @include mq() {
                width: auto;
            }
        }
		&-date {
			font-size: 15px;
			color: #aaaaaa;
            white-space: nowrap;
		}

		&-category {
            font-size: 13px;
            color: #aaaaaa;
            margin: 0 0 0 15px;
            padding-top: 2px;
        }

		&-name {
            font-size: 15px;
            color: #333333;
            word-break: break-all;
            margin: 0;
            .ss-newarrived-blog__link:hover & {
                @include mq(hover) {
                    color: #666;
                }
            }
        }

	}
	&__message {
		padding: 48px 15px;
		background: #eee;
		text-align: center;
	}
}
